import React, { useEffect } from "react";
import $ from "jquery";
import SpreadsheetTable from "./Sheets";

interface AntsOptions {
  start: number;
  max: number;
}

declare global {
  interface Window {
    Ants: new (options: AntsOptions) => void;
    $: JQueryStatic;
    jQuery: JQueryStatic;
  }
}

const AntpireSPA: React.FC = () => {
  useEffect(() => {
    window.$ = window.jQuery = $;

    const script = document.createElement("script");
    script.src = "ants.js";
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      console.log("ants.js loaded");
      if (window.Ants) {
        console.log("Ants constructor exists");
        try {
          new window.Ants({ start: 10, max: 25 });
          console.log("Ants initialized");
        } catch (error) {
          console.error("Error initializing Ants:", error);
        }
      } else {
        console.error("Ants constructor not found");
      }
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div style={{ minHeight: "100vh", padding: "20px" }}>
      <div
        style={{
          width: "100%",
          maxWidth: "600px",
          margin: "0 auto 20px auto",
          textAlign: "center",
        }}
      >
        <img
          src="/antpire-banner.png"
          alt="The Antpire"
          style={{ width: "100%", height: "auto", marginBottom: "20px" }}
        />
      </div>
      <p>
        Meet Wolfgang, a passionate 14-year-old who has turned his love for ants
        into an exciting live-streamed adventure. Here, you'll get an up-close
        look at the fascinating world of ants as Wolfgang shares his knowledge
        and enthusiasm for these incredible creatures. Every day, Wolfgang takes
        you inside his meticulously crafted ant farm, where you can witness the
        day-to-day activities of his ant colonies—from foraging and tunneling to
        teamwork and communication. Whether you're an ant enthusiast or just
        curious about the tiny world beneath our feet, Wolfgang's streams on
        Twitch and YouTube Live offer a unique and educational experience for
        viewers of all ages. Join Wolfgang as he uncovers the secrets of these
        hardworking insects, shares fun facts, and answers your questions live.
        Don’t miss out on this journey into the miniature world of ants, guided
        by one of the youngest and most dedicated ant keepers around. Tune in,
        learn, and become part of the ant colony community!
      </p>
      <SpreadsheetTable />
    </div>
  );
};

export default AntpireSPA;
