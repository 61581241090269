import React from "react";
import AntpireSPA from "./AntpireSPA";
import TwitchEmbed from "./TwitchEmbed";
import { SocialIcon } from "react-social-icons";

const App: React.FC = () => {
  return (
    <div className="App">
      <AntpireSPA />
      <TwitchEmbed channel="theantpire" />
      <div style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
        <SocialIcon
          url="https://www.twitch.tv/theantpire"
          network="twitch"
          style={{ height: 40, width: 40 }}
        />
        <SocialIcon
          url="https://www.youtube.com/@theantpire/live"
          network="youtube"
          style={{ height: 40, width: 40 }}
        />
      </div>
    </div>
  );
};

export default App;
