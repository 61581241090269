import React, { useEffect, useState } from "react";

interface SpreadsheetData {
  values: string[][];
}

const SpreadsheetTable: React.FC = () => {
  const [data, setData] = useState<string[][]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://sheets.googleapis.com/v4/spreadsheets/1VB0DkUg4ZRI3rPN-X39jErp8NfuXsuLBryCrl29QqTI/values/Sheet1!A1:Z1000?key=AIzaSyBqM7BxmiPEf1OW6jT16RipTytwU1b4tVw`
        );
        const result: SpreadsheetData = await response.json();
        setData(result.values);
      } catch (error) {
        console.error("Error fetching the data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <table border={1}>
        <thead>
          <tr>
            {data[0]?.map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.slice(1).map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, cellIndex) => (
                <td key={cellIndex}>{cell}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SpreadsheetTable;
