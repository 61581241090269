import React, { useEffect, useRef } from "react";

interface TwitchEmbedProps {
  channel: string;
  width?: string;
  height?: string;
}

const TwitchEmbed: React.FC<TwitchEmbedProps> = ({
  channel,
  width = "100%",
  height = "480",
}) => {
  const embedRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://embed.twitch.tv/embed/v1.js";
    script.async = true;
    script.onload = () => {
      if (embedRef.current) {
        new window.Twitch.Embed(embedRef.current, {
          width,
          height,
          channel,
        });
      }
    };
    document.body.appendChild(script);

    return () => {
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, [channel, width, height]);

  return <div ref={embedRef}></div>;
};

export default TwitchEmbed;
